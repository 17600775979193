// set questionnaire to window height
var calculateLayout = function () {
    controls_height = $('.js--controls').height();
    menu_padding = $('.container.u__mt50').outerHeight();
    header = $('.js--header').outerHeight();
    $('.js-view--scrollable').css('height', $(window).height() - header - controls_height - menu_padding - 50);

};

var lazyLayout = _.debounce(calculateLayout, 300);
$(window).resize(lazyLayout);

$(window).on('load', function () {
    calculateLayout();
});


$('*[data-max-length]').on('keydown', function () {
    $el = $(this);
    val = $el.val();
    max = $el.data('max-length');

    if (val.length > max) {
        $el.css('border', '1px solid red');
    } else {
        $el.css('border', '');
    }
});

$('*[data-max-length]').trigger('keydown');

// delete participant

$('.js-participant-delete').on('click', function () {
    $el = $(this);
    swal({
        title: 'Möchten Sie diesen Teilnehmer löschen?',
        text: 'Sie können diesen Vorgang nicht rückgängig machen!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Nein',

    }).then(function (isConfirm) {
        if (isConfirm === true) {

            // DELETE REQUEST TO SERVER
            var form =
                $('<form>', {
                    'method': 'POST',
                    'action': $el.data('url')
                });

            var token =
                $('<input>', {
                    'type': 'hidden',
                    'name': '_token',
                    'value': $('body').data('token')
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': 'delete'
                });

            form.append(token, hiddenInput)
                .appendTo('body').submit();


        } else if (isConfirm === false) {
        } else {
        }
    })
});
